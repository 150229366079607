import { graphql, useStaticQuery } from "gatsby"

const useInformation = () => {
  const data = useStaticQuery(graphql`
    {
      wordPress {
        pages(where: { title: "Генеральный план города Перми" }, first: 1) {
          nodes {
            content
            title
          }
        }
      }
    }
  `)

  return data.wordPress.pages.nodes[0]
}

export default useInformation
