import React from "react"
import Article from "../../components/layout/articleRenderer"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"
import useGenPlan from "../../hooks/use-genplan"
import { css } from "@emotion/react"

const ImagesWrapper = styled.div`
  display: flex;
`

const imageStyles = css`
  max-width: 50%;
  margin-right: 16px;
  height: auto;
`

const LeftColumn = () => {
  return (
    <ImagesWrapper>
      <StaticImage
        src="../../images/general-plan/book1.png"
        alt="Обложка книги генерального плана города Перми, первая"
        layout="constrained"
        placeholder="tracedSVG"
        css={css`
          ${imageStyles}
        `}
      />
      <StaticImage
        src="../../images/general-plan/book2.png"
        alt="Обложка книги генерального плана города Перми, вторая"
        layout="constrained"
        placeholder="tracedSVG"
        css={css`
          ${imageStyles}
        `}
      />
    </ImagesWrapper>
  )
}

export default function GeneralPlan() {
  const genPlanData = useGenPlan()

  return (
    <Article
      anchorIcon
      wordPressArticle={genPlanData}
      leftColumn={<LeftColumn />}
    />
    // <Sidebar
    //   heading="Генеральный план города Перми"
    //   sticky
    //   leftColumn={<LeftColumn />}
    // >
    //   <div dangerouslySetInnerHTML={{ __html: genPlanData.content }} />
    // </Sidebar>
  )
}
